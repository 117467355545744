class Quote {
  constructor({ data, readOnly }) {
    this.data = data;
    this.readOnly = readOnly;
  }

  static get toolbox() {
    return {
      title: "Quote",
      icon: '<svg width="17" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.315 3.401c-1.61 0-2.916 1.343-2.916 3 0 1.656 1.306 3 2.916 3 2.915 0 .972 5.799-2.916 5.799v1.4c6.939.001 9.658-13.199 2.916-13.199zm8.4 0c-1.609 0-2.915 1.343-2.915 3 0 1.656 1.306 3 2.915 3 2.916 0 .973 5.799-2.915 5.799v1.4c6.938.001 9.657-13.199 2.915-13.199z"/></svg>',
    };
  }

  render() {
    let blockquote = document.createElement("blockquote");
    blockquote.className = "blockquote";
    const paragraph = document.createElement("p");
    paragraph.innerText =
      this.data && this.data.quote ? this.data.quote : `Write your quote here`;
    paragraph.className = "mb-0";
    if (!this.readOnly) paragraph.contentEditable = "true";
    paragraph.ref = "paragraph";
    paragraph.placeholder = "PLACEHOLDER";

    blockquote.append(paragraph);

    const br = document.createElement("br");
    blockquote.append(br);

    const cite = document.createElement("cite");
    cite.title = "source Title";
    cite.innerText =
      this.data && this.data.author ? this.data.author : "Who quoted this?";
    if (!this.readOnly) cite.contentEditable = "true";

    let footer = document.createElement("footer");
    footer.className = "blockquote-footer";

    footer.append(cite);

    blockquote.append(footer);

    return blockquote;
  }

  save(blockContent) {
    return {
      quote: blockContent.firstChild.innerText,
      author: blockContent.lastChild.innerText,
    };
  }

  static get isReadOnlySupported() {
    return true;
  }
}

export default Quote;
