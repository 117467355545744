import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import { connect } from "react-redux";

const Notification = ({ openConfirmation, dispatch }) => {
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (openConfirmation.open) {
      setAlertOpen(true);
      window.setTimeout(() => {
        setAlertOpen(false);
        dispatch({
          type: "CONFIRMATION",
          value: { color: "success", title: "", open: false },
        });
      }, 2000);
    }
  }, [openConfirmation, dispatch]);

  return (
    <Alert
      color={openConfirmation.color}
      isOpen={alertOpen}
      className="m-0 w-100"
      style={{
        position: "fixed",
        zIndex: 99999,
        top: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        lineHeight: 2.5,
        overflow: "hidden",
        webkitAnimation: "slideDown 2.5s 1.0s 1 ease forwards",
        webkitTransform: "translateY(75px)",
      }}
    >
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => setAlertOpen(false)}
      >
        <i className="nc-icon nc-simple-remove" />
      </button>
      <span>{openConfirmation.title}</span>
    </Alert>
  );
};

const mapStateToProps = (state) => ({
  openConfirmation: state.all.openConfirmation,
});

export default connect(mapStateToProps)(Notification);
