import React, { useEffect, useState } from "react";
import MainNavbar from "../../components/Navbars/MainNavbar";
import OtherPageHeader from "../../components/Headers/OtherPageHeader";
import MainFooter from "../../components/Footers/MainFooter";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import API from "../../services/API";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// styles
import styles from "../../assets/scss/styles.module.scss";
import ImageUploadPreview from "../../components/Functional/ImageUploadPreview";

const CalendarForm = ({ dispatch }) => {
  const [calendarEntry, setCalendarEntry] = useState({
    title: "",
    date_begin: new Date().toISOString().substring(0, 10),
    date_end: undefined,
    feature_image: null,
    location: "",
    entry_type: "",
  });
  const history = useHistory();
  const { id } = useParams();
  const [endDateDisable, setEndDateDisable] = useState(false);
  const [validImage, setValidImage] = useState(true);

  const categoryOptions = [
    { title: "Club", key: "club" },
    { title: "KPC", key: "kpc" },
    { title: "JKA", key: "jka" },
    { title: "All", key: "all" },
  ];

  useEffect(() => {
    async function getCalendarEntries(id) {
      const url = process.env.REACT_APP_API_URL + "/CalendarEntries/" + id;
      const response = await API.getAPIData(url, "GET", null);
      if (response.status === 200) {
        let data = await response.json();
        setCalendarEntry(data);
        if (data.date_end !== null) {
          setEndDateDisable(true);
        } else if (data.date_end === data.date_begin) {
          setEndDateDisable(false);
        }
      }
    }

    if (id !== undefined) {
      getCalendarEntries(id).then(() => {});
    }
  }, [id]);

  async function submitCalendarEntry() {
    // Submit feature image
    calendarEntry.feature_image = await handleSubmission();
    const url =
      process.env.REACT_APP_API_URL +
      "/CalendarEntries/" +
      (id ? id + "/" : "");
    const method = id ? "PUT" : "POST";
    const response = await API.getAPIData(url, method, calendarEntry);
    if (response.status === 201 || response.status === 200) {
      dispatch({
        type: "CONFIRMATION",
        value: { color: "success", title: "Submission successful", open: true },
      });
      history.goBack();
    }
  }

  async function deleteCalendarEntry() {
    const url = process.env.REACT_APP_API_URL + "/CalendarEntries/" + id;
    const response = await API.getAPIData(url, "DELETE", calendarEntry);
    // // Delete was successful
    if (response.status === 204) {
      dispatch({
        type: "CONFIRMATION",
        value: { color: "success", title: "Successfully delete", open: true },
      });
      history.goBack();
    }
  }

  const imageChangeHandler = (event) => {
    const uploadedImage = {
      title: event.target.files[0].name,
      file: event.target.files[0],
    };
    setCalendarEntry({ ...calendarEntry, feature_image: uploadedImage });
  };

  async function handleSubmission() {
    if (calendarEntry.feature_image === null) {
      setValidImage(false);
    } else {
      if (typeof calendarEntry.feature_image !== "string") {
        const formData = new FormData();

        formData.append("title", calendarEntry.feature_image.title);
        formData.append("file", calendarEntry.feature_image.file);

        const url = process.env.REACT_APP_API_URL + "/Images/";
        const response = await API.UploadImage(url, formData);
        if (response.status !== 201) {
          console.log("An error occurred during image upload.");
        } else {
          const data = await response.json();
          return data.file; // Return URL
        }
      } else {
        return calendarEntry.feature_image;
      }
    }
  }

  function toggleEndDate(event) {
    setEndDateDisable(!endDateDisable);
    if (event.target.checked) {
      setCalendarEntry({
        ...calendarEntry,
        date_end: calendarEntry.date_begin,
      });
    } else {
      if (calendarEntry.date_end) {
        setCalendarEntry({
          ...calendarEntry,
          date_end: calendarEntry.date_begin,
        });
      } else {
        setCalendarEntry({
          ...calendarEntry,
          date_end: undefined,
        });
      }
    }
  }

  return (
    <>
      <MainNavbar isBlackBg={true} />
      <OtherPageHeader />
      <div className="main">
        {
          <Container className="tim-container">
            <Row>
              <Col className="ml-auto mr-auto" lg="12">
                <Card className={styles.cardForm}>
                  <Form>
                    <FormGroup>
                      <h3>
                        {id ? "EDIT CALENDAR ENTRY" : "ADD CALENDAR ENTRY"}
                      </h3>
                    </FormGroup>
                    <FormGroup>
                      <Label>Title</Label>
                      <Input
                        placeholder="Title"
                        type="text"
                        onChange={(event) =>
                          setCalendarEntry({
                            ...calendarEntry,
                            title: event.target.value,
                          })
                        }
                        value={calendarEntry.title}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Start Date</Label>
                      <Input
                        placeholder="Date"
                        type="date"
                        onChange={(event) =>
                          setCalendarEntry({
                            ...calendarEntry,
                            date_begin: event.target.value,
                          })
                        }
                        value={calendarEntry.date_begin}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>End date </Label>
                      <br />
                      <Input
                        checked={endDateDisable}
                        onChange={(e) => toggleEndDate(e)}
                        type="checkbox"
                      />{" "}
                      <Label check>Select if end date is required.</Label>{" "}
                      <br />
                      <Input
                        disabled={!endDateDisable}
                        placeholder="Date"
                        type="date"
                        onChange={(event) =>
                          setCalendarEntry({
                            ...calendarEntry,
                            date_end: event.target.value,
                          })
                        }
                        value={
                          endDateDisable
                            ? calendarEntry.date_end
                            : calendarEntry.date_begin
                        }
                      />
                    </FormGroup>

                    <ImageUploadPreview
                      invalid={!validImage}
                      changeHandler={imageChangeHandler}
                      imageUrl={
                        typeof calendarEntry.feature_image === "string"
                          ? calendarEntry.feature_image
                          : null
                      }
                    />

                    <FormGroup>
                      <Label>Location</Label>
                      <Input
                        placeholder="Location"
                        type="text"
                        onChange={(event) =>
                          setCalendarEntry({
                            ...calendarEntry,
                            location: event.target.value,
                          })
                        }
                        value={calendarEntry.location}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Calendar Entry Category</Label>
                      {categoryOptions.map((value) => (
                        <div key={value.key} className="form-check-radio">
                          <Label check>
                            <Input
                              defaultValue={value.key}
                              id="exampleRadios1"
                              name="category"
                              type="radio"
                              checked={calendarEntry.entry_type === value.key}
                              onChange={(event) =>
                                setCalendarEntry({
                                  ...calendarEntry,
                                  entry_type: event.target.value,
                                })
                              }
                            />
                            {value.title} <span className="form-check-sign" />
                          </Label>
                        </div>
                      ))}
                    </FormGroup>

                    <br />

                    {/* <Button
                    color="success"
                    outline
                    size="sm"
                    type="button"
                    className="mr-1"
                    onClick={submitCalendarEntry}
                  >
                    Submit
                  </Button>
                  {id && (
                    <Button
                      color="danger"
                      outline
                      size="sm"
                      type="button"
                      className="mr-1"
                      onClick={deleteCalendarEntry}
                    >
                      Delete
                    </Button>
                  )} */}
                    <Row>
                      <Col>
                        <Button
                          color="success"
                          outline
                          size="sm"
                          type="button"
                          className="mr-1"
                          onClick={submitCalendarEntry}
                        >
                          Submit
                        </Button>

                        {id && (
                          <Button
                            color="danger"
                            outline
                            size="sm"
                            type="button"
                            className="mr-1"
                            onClick={deleteCalendarEntry}
                          >
                            Delete
                          </Button>
                        )}
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Button
                          color="danger"
                          outline
                          size="sm"
                          type="button"
                          className="mr-1"
                          onClick={(e) => {
                            history.goBack();
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>
      <MainFooter />
    </>
  );
};

export default connect()(CalendarForm);
