import React, { useEffect, useState } from "react";
import MainNavbar from "../../components/Navbars/MainNavbar";
import OtherPageHeader from "../../components/Headers/OtherPageHeader";
import MainFooter from "../../components/Footers/MainFooter";
import {
  Button,
  Card,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import API from "../../services/API";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// styles
import styles from "../../assets/scss/styles.module.scss";
import ImageUploadPreview from "../../components/Functional/ImageUploadPreview";

const InstructorForm = ({ dispatch }) => {
  const [instructor, setInstructor] = useState({
    name: "",
    image: {},
    rank: "",
    description: "",
    social: [],
    is_head_instructor: false,
  });
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    async function getInstructor(id) {
      const url = process.env.REACT_APP_API_URL + "/Instructors/" + id;
      const response = await API.getAPIData(url, "GET", null);
      if (response.status === 200) {
        let data = await response.json();
        setInstructor(data);
      }
    }

    if (id !== undefined) {
      getInstructor(id).then(() => {});
    }
  }, [id]);

  async function submitInstructor() {
    // Clean social array
    instructor.social = instructor.social.filter((value) => value.link !== "");

    const url =
      process.env.REACT_APP_API_URL + "/Instructors/" + (id ? id + "/" : "");
    const method = id ? "PUT" : "POST";

    const response = await API.getAPIData(url, method, instructor);
    if (response.status === 201 || response.status === 200) {
      dispatch({
        type: "CONFIRMATION",
        value: { color: "success", title: "Submission successful", open: true },
      });
      history.goBack();
    }
  }

  async function deleteInstructor() {
    const url = process.env.REACT_APP_API_URL + "/Instructors/" + id;
    const response = await API.getAPIData(url, "DELETE", instructor);
    // // Delete was successful
    if (response.status === 204) {
      dispatch({
        type: "CONFIRMATION",
        value: { color: "success", title: "Successfully delete", open: true },
      });
      history.goBack();
    }
  }

  const imageChangeHandler = (event) => {
    let reader = new FileReader();
    reader.onloadend = function () {
      setInstructor({
        ...instructor,
        image: { file: reader.result },
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  function SocialUrlInput(title, type) {
    return (
      <FormGroup key={type}>
        <Label>{title}</Label>
        <Input
          placeholder={title}
          type="text"
          onChange={(event) => {
            let socialArray = instructor.social;
            const index = socialArray.findIndex(
              (value) => value["platform"] === type
            );
            if (index < 0) {
              socialArray.push({
                platform: type,
                link: event.target.value,
              });
            } else {
              socialArray[index].link = event.target.value;
            }
            setInstructor({
              ...instructor,
              social: socialArray,
            });
          }}
          value={
            instructor.social.find((value) => value["platform"] === type)
              ?.link ?? ""
          }
        />
      </FormGroup>
    );
  }

  return (
    <>
      <MainNavbar isBlackBg={true} />
      <OtherPageHeader />
      <div className="main">
        {
          <Container className="tim-container">
            <Row>
              <Col className="ml-auto mr-auto" lg="12">
                <Card className={styles.cardForm}>
                  <Form>
                    <FormGroup>
                      <h3>{id ? "EDIT INSTRUCTOR" : "ADD INSTRUCTOR"}</h3>
                    </FormGroup>
                    <FormGroup>
                      <Label>Name</Label>
                      <Input
                        placeholder="Name"
                        type="text"
                        onChange={(event) =>
                          setInstructor({
                            ...instructor,
                            name: event.target.value,
                          })
                        }
                        value={instructor.name}
                      />
                    </FormGroup>

                    <ImageUploadPreview
                      changeHandler={imageChangeHandler}
                      imageUrl={
                        instructor.image !== null ? instructor.image.file : null
                      }
                    />

                    <FormGroup>
                      <Label>Rank</Label>
                      <Input
                        placeholder="Rank"
                        type="text"
                        onChange={(event) =>
                          setInstructor({
                            ...instructor,
                            rank: event.target.value,
                          })
                        }
                        value={instructor.rank}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        placeholder="Description"
                        type="text"
                        onChange={(event) =>
                          setInstructor({
                            ...instructor,
                            description: event.target.value,
                          })
                        }
                        value={instructor.description}
                      />
                    </FormGroup>
                    {SocialUrlInput("LinkedIn URL", "linkedin")}
                    {SocialUrlInput("Facebook URL", "facebook")}
                    {SocialUrlInput("Instagram URL", "instagram")}
                    {SocialUrlInput("Twitter URL", "twitter")}
                    {SocialUrlInput("Email Address", "email")}

                    <FormGroup>
                      <Label>Is Head Instructor?</Label>
                      <div>
                        <CustomInput
                          type="switch"
                          id="HeadInstructor"
                          name="customSwitch2"
                          label=""
                          checked={instructor.is_head_instructor}
                          onChange={(event) => {
                            setInstructor({
                              ...instructor,
                              is_head_instructor: event.target.checked,
                            });
                          }}
                        />
                      </div>
                    </FormGroup>
                    <Row>
                      <Col>
                        <Button
                          color="success"
                          outline
                          size="sm"
                          type="button"
                          className="mr-1"
                          onClick={submitInstructor}
                        >
                          Submit
                        </Button>

                        {id && (
                          <Button
                            color="danger"
                            outline
                            size="sm"
                            type="button"
                            className="mr-1"
                            onClick={deleteInstructor}
                          >
                            Delete
                          </Button>
                        )}
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <Button
                          color="danger"
                          outline
                          size="sm"
                          type="button"
                          className="mr-1"
                          onClick={(e) => {
                            history.goBack();
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        }
      </div>

      <MainFooter />
    </>
  );
};

export default connect()(InstructorForm);
