import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";

class ImageUploadPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: "", imagePreviewUrl: "" };
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
    this.props.changeHandler(e);
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl || this.props.imageUrl) {
      $imagePreview = (
        <Image
          src={this.props.imageUrl ? this.props.imageUrl : imagePreviewUrl}
          thumbnail
        />
      );
    } else {
      $imagePreview = <div className="previewText" />;
    }

    return (
      <Container className="pb-4">
        <Row>
          <Col xs={12} md={12}>
            <label>Please Select Image</label>
            <br />
            <input type="file" onChange={(e) => this._handleImageChange(e)} />
          </Col>
          <Col xs={4} md={4}>
            {$imagePreview}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ImageUploadPreview;
