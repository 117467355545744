import React from "react";

// reactstrap components
import { Row, Container, Button } from "reactstrap";

function MainFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made by
              <Button
                style={{ padding: "5px", marginBottom: "3px" }}
                className="btn-link"
                color="danger"
                target="_blank"
                href="https://intentio.co.za"
              >
                Intentio
              </Button>
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default MainFooter;
