const INITIAL_STATE = {
  openConfirmation: { color: "success", title: "Default", open: false },
  contactScroll: false,
};

function allReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "CONFIRMATION": {
      return {
        ...state,
        openConfirmation: action.value,
      };
    }
    case "SCROLL": {
      return {
        ...state,
        contactScroll: action.value,
      };
    }
    default:
      return state;
  }
}

export default allReducer;
