import React from "react";

// reactstrap components
import { Button, Container, Row } from "reactstrap";

// core components

function SectionPolicies() {
  const LinkComponent = ({ url, title }) => {
    return (
      <div>
        <Button
          style={{ padding: "0px" }}
          target="_blank"
          href={url}
          className="btn-link mr-1"
          color="info"
        >
          {title}
        </Button>
      </div>
    );
  };

  return (
    <Container className="tim-container" style={{ justifyContent: "center" }}>
      <div id="dojokun">
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="typography-line">
            <h3>POLICIES</h3>
          </div>
          <div className="typography-line">
            <h6>
              Solis Ortus holds the right to make changes to the policy as it is
              still in draft version.
            </h6>
            <LinkComponent
              title="Competition Policy"
              url="http://next.solisortus.co.za/wp-content/uploads/2018/05/DRAFT_COMPETITION-POLICY_V2.pdf"
            />
            <LinkComponent
              title="Grading Policy"
              url="http://next.solisortus.co.za/wp-content/uploads/2018/05/GRADING-POLICY_revised_v2-3_FIN.pdf"
            />
            <LinkComponent
              title="Shodan Policy"
              url="http://next.solisortus.co.za/wp-content/uploads/2018/04/Requirements-for-Shodan-Grading_v2.pdf"
            />
            <LinkComponent
              title="Nidan Policy"
              url="http://next.solisortus.co.za/wp-content/uploads/2017/04/Requirements-for-Nidan-and-Up-Grading_v1.pdf"
            />
            <LinkComponent
              title="Special Needs Policy"
              url="http://next.solisortus.co.za/wp-content/uploads/2018/05/DISABILITY-GRADING-POLICY_revised_v2-4_FIN.pdf"
            />
            <LinkComponent
              title="Instructors Policy"
              url="http://next.solisortus.co.za/wp-content/uploads/2018/08/DRAFT_INSTRUCTORS-POLICY_V1.pdf"
            />
            <LinkComponent
              title="Club Policy"
              url="http://next.solisortus.co.za/wp-content/uploads/2018/05/DRAFT_SO-POLICY_V1.pdf"
            />
          </div>
        </Row>
      </div>
    </Container>
  );
}

export default SectionPolicies;
