import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

const SectionDojoEtiquette = () => {
  return (
    <Container className="tim-container" style={{ justifyContent: "center" }}>
      <div id="dojokun">
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="typography-line">
            <h3>DOJO ETIQUETTE</h3>
            <br />
            <h6>What is Dojo Etiquette</h6>
            <br />
            <p>
              As Master Funakoshi states, “Without courtesy you cannot practice
              Karate-do.”
            </p>
            <br />

            <p>This applies not only to our training but to life in general.</p>
            <br />

            <p>
              The word “dojo” is actually two words. “Do” which means “the
              way”or “the path” and “jo” which means”the place.” When the two
              words are combined it means “the place where the way is studied.”
              The dojo is the place where we learn to live and train together as
              human beings. This is a serious subject and therefore we must
              always follow dojo etiquette.
            </p>
            <br />

            <p>This is the first step to practicing Karate-do.</p>
            <br />

            <p>
              In any karate dojo one of the most important rules of etiquette is
              behaviour.
            </p>
            <br />

            <p>
              Since by nature we all learn by trial and error, many things will
              be forgiven in a dojo, but bad behaviour is definitely not one of
              them. This rule applies to every student within the dojo society
              regardless of their rank, in fact the higher the rank, the less
              tolerance there is for any breach of etiquette what so ever.
              Starting with the “sensei” or “teacher” down through the
              “sempai’s” or “assistants” in the black belt ranks, and then
              finally through the “kyu” or coloured belt ranks, known as “kohai”
              it is the responsibility of each student to make sure that those
              who follow in their foot steps, do so with the highest possible
              level of personal behaviour. It is very important to remember,
              however, that correction for acts of misbehaviour always come from
              the top down, not the bottom up.
            </p>
            <br />
            <h6>General Dojo Etiquette</h6>
            <br />
            <ol>
              <li>
                All instructors with a belt grade of Sandan(3rd Dan) and above
                are referred to as “Sensei” on and off the floor.
              </li>
              <li>
                Greet the Sensei’s with a correct bow. A sloppy bow or bowing
                while seated would be considered rude.
              </li>
              <li>
                Formally introduce guests to the Sensei. Guests are subject to
                the rules and regulations of the dojo. Students are accountable
                for the behavior of their guests.
              </li>
              <li>
                No food or drink is allowed on the dojo floor. Individual water
                bottles may be kept at the side of the dojo for use on water
                breaks and after class.
              </li>
              <li>
                Do not engage in loud talking, horseplay or other disruptive
                activities on the dojo floor. Work to maintain the dojo
                atmosphere at all times .
              </li>
              <li>
                Ask permission of the Instructor if iyou require to be excused
                early from class.
              </li>
              <li>
                Consider the dojo as your home. Take responsibility for its
                atmosphere, its orderliness and its cleanliness.
              </li>
              <li>
                All karate students) shall conduct themselves in a manner that
                will reflect the utmost humility and respect for themselves and
                their dojo.
              </li>
              <li>
                A person causing damage to Solis Ortus property shall be liable
                for repair or replacement.
              </li>
              <li>
                It is inappropriate for any student to visit another dojo,
                participate in a tournament or demonstration, or teach karate
                outside the dojo without prior permission of the Instructor.
              </li>
              <li>
                These rules are intended as a general guideline. A student
                should endeavor to behave in accordance with good manners,
                common decency and common sense.
              </li>
            </ol>
            <br />

            <h6>General Dojo Etiquette</h6>
            <br />
            <ol>
              <li>
                Bad language or an aggressive attitude is prohibited in the
                dojo, waiting area or change rooms.
              </li>
              <li>
                Ensure that you know the grades of all other Karateka in
                attendance and that you line up in the correct grade order. If
                in doubt, err on the side of caution and go closer to the end of
                the line rather than the beginning.
              </li>
              <li>
                If arriving late for a class, enter the dojo discreetly out of
                the way until the instructor gives permission for you to join
                the class.
              </li>
              <li>
                If leaving early for any reason, do so discreetly with the
                permission of the instructor, which should preferably be
                obtained prior to the commencement of the class.
              </li>
              <li>
                When a Karateka is not actively participating, never sit with
                the legs outstretched in the dojo. Either , sit cross-legged or
                actively stretch.
              </li>
              <li>
                Never stand with arms crossed or hands placed on the hips. Arms
                should either hang loosely at the sides or with the arms
                straight and the hands clasped gently in front or behind.
              </li>
              <li>
                Pay attention to the manner in which the instructor requires the
                counting to be done. This may either be done only by the seniors
                or, in the usual case ,is done by every person in attendance,
                each taking a turn, usually in a circle.
              </li>
              <li>Leave the change rooms in a clean condition.</li>
              <li>
                All <b>punches, kicks</b> and <b>strikes</b>, when training with
                a partner, must be controlled and no contact is permitted.
                Blocking techniques to appropriate parts of ones partners legs
                and arms may make contact.
              </li>
              <li>Read the dojo noticeboard and website regularly.</li>
              <li>
                When pairing off to work with a partner where there is an uneven
                number of participants, never allow a senior grade to be without
                a partner.
              </li>
              <li>
                Show kindness to karate-ka weaker or younger than yourself and
                assist beginners where appropriate.
              </li>
              <li>
                Where there is an odd number, the partnerships are continually
                rotated with one person being out for each exercise. Priority is
                always given to the senior grades.
              </li>
              <li>
                When pairing off to work with a partner, the Karateka should
                never walk away from a senior grade who has indicated that they
                wish to work with them.
              </li>
              <li>
                When working with a partner, the Karateka should be aware of the
                level of contact that is being made. Excessive contact should
                not be made in partner work, especially where the defender is
                not blocking.
              </li>
              <li>
                When the Instructor asks a question or gives a command, the
                Karateka should indicate that they understand and acknowledge
                the instruction or question. This is usually done by responding
                with <i>“oss”</i> or <i>“hai”</i> (yes).
              </li>
              <li>No smoking is permitted in the dojo.</li>
              <li>Do not partake of alcohol before training.</li>
              <li>Do not chew gum or food on the dojo floor.</li>
            </ol>
            <br />

            <h6>Bowing Procedures</h6>
            <br />
            <ol>
              <li>
                Your first bow is to the front of the class, as a general bow to
                your fellow Karateka and the second bow is to your instructor.
                This is done at the beginning and the end of a class.
              </li>
              <li>
                When bowing to your fellow Karateka, you are thanking him for
                training with you.
              </li>
              <li>
                The second bow, to the instructors, is thanks for his
                instruction and your respect for him/her.
              </li>
              <li>
                When pairing off to work with a partner always give a mutual bow
                before and after the exercise.
              </li>
            </ol>
            <br />

            <h6>Dress Code</h6>
            <br />
            <ol>
              <li>
                Males – no t-shirt under karate suit.{" "}
                <i>
                  (When training in winter mornings and it is very cold,
                  instructors may allow students to wear a white t-shirt under
                  the gi.)
                </i>
              </li>
              <li>
                Females – may wear a white t-shirt or crop top which should
                preferably not be visible.
              </li>
              <li>
                No visible jewelry of any kind, watches or metal hairclips. Any
                jewelry that cannot be removed for any reason should have tape
                applied over it. This is for the safety of all parties.
              </li>
              <li>Dojo badge should be on the left hand breast of the suit.</li>
              <li>
                Do not roll the cuffs of the pants or the sleeves. This is
                dangerous to the training partner.
              </li>
              <li>
                Body, feet, hands and karate-gi (training outfit) should be
                clean before engaging in training.
              </li>
              <li>
                Hand and toe nails should be short and clean with no rough or
                sharp edges. Ladies may have slightly longer nails as long as
                they are not sharp and are not a hazard to training partners.
              </li>
              <li>
                Your obi (belt) should be correctly tied (ask a senior to show
                you how your belt should be tied).
              </li>
              <li>Do not enter the dojo with shoes on.</li>
            </ol>
            <br />

            <h6>Parents Etiquette</h6>
            <br />
            <ol>
              <li>
                A word with a sensei will only be allowed during the warm-up
                sessions.
              </li>
              <li>
                Sensei Eugene must be present when a word is spoken with another
                sensei/sempai.
              </li>
              <li>Parents are not allowed inside the dojo during gradings.</li>
              <li>Classes may not be disturbed except for emergencies.</li>
              <li>
                Parents may not distract their children during instructing of a
                class.
              </li>
              <li>Any enquiries between 08:00 – 15:00.</li>
              <li>Parents must please be quiet during classes</li>
              <li>
                All class fees must please be settled before the 5th of every
                month, otherwise a R50 weekly fine will be charged, which will
                be included with the fees.
              </li>
              <li>
                All queries and problems must please be taken to Sensei Eugene.
              </li>
            </ol>
          </div>
        </Row>
      </div>
    </Container>
  );
};

export default SectionDojoEtiquette;
