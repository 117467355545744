import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";
import API from "../../services/API";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AddFab from "../../components/Functional/AddFab";

// core components

const SectionCalendar = ({ isSignedIn }) => {
  const [calendarEntry, setCalendarEntry] = useState([]);
  const [monthYears, setMonthYears] = useState([]);
  const [isSummary, setIsSummary] = useState(false);
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    async function getCalendarEntries() {
      const url = process.env.REACT_APP_API_URL + "/CalendarEntries/";
      const response = await API.APICallUnauthorized(url, "GET", null);
      if (response.status === 200) {
        let data = await response.json();
        setCalendarEntry(data);
        const monthYears = data.map((entry) =>
          new Date(entry.date_begin).setDate(1)
        );
        const uniqueMonthYears = monthYears
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort(function (a, b) {
            return a - b;
          });
        setMonthYears(uniqueMonthYears);
      }
    }

    getCalendarEntries().then(() => {});
  }, [isSummary]);

  function renderDate(value) {
    let startDate = new Date(value.date_begin);
    let endDate = new Date(value.date_end);
    let options = { day: "numeric", month: "short" };
    let optionsNoYear = { month: "short", day: "numeric" };
    if (startDate >= endDate) {
      return <>{startDate.toLocaleDateString("en-ZA", options)}</>;
    } else {
      let startDateStr = startDate.toLocaleDateString("en-ZA", optionsNoYear);
      let endDateStr = endDate.toLocaleDateString("en-ZA", options);
      return (
        <>
          {startDateStr} - {endDateStr}
        </>
      );
    }
  }

  let itemRows = (value) => {
    return (
      <>
        <tr
          key={value.id}
          to={isSignedIn ? `/club/calendar/form/${value.id}` : "#"}
        >
          <td>{renderDate(value)}</td>
          <td>
            <img
              src={value.feature_image}
              alt="..."
              style={{ height: 64, aspectRatio: 1, objectFit: "fill" }}
            ></img>
          </td>

          <td>{value.title}</td>
          <td>{value.location}</td>
        </tr>
      </>
    );
  };

  let itemCards = (value, index, month) => {
    return (
      <Col key={`${index}-${month}`} md="3">
        <Link to={isSignedIn ? `/club/calendar/form/${value.id}` : "#"}>
          {isSignedIn ? (
            <Card
              style={{
                display: "flex",
                textAlign: "center",
                width: "auto",
                cursor: "pointer",
              }}
            >
              <CardImg
                top
                src={value.feature_image}
                alt="..."
                style={{ height: 100, aspectRatio: 1, objectFit: "contain" }}
              />
              <CardBody style={{ fontWeight: "bold", color: "white" }}>
                <hr />
                <CardTitle>{value.title}</CardTitle>
                <hr />
                <CardText className="text-center">
                  <i className="nc-icon nc-calendar-60" />
                  <br />
                  {renderDate(value)}
                </CardText>
                {value.location && (
                  <>
                    <hr />
                    <CardText className="text-center">
                      <i className="nc-icon nc-pin-3" />
                      <br />
                      {value.location}
                    </CardText>
                  </>
                )}
              </CardBody>
            </Card>
          ) : (
            <Card
              style={{
                display: "flex",
                textAlign: "center",
                width: "auto",
                cursor: "default",
                transform: "none",
              }}
            >
              <CardImg
                top
                src={value.feature_image}
                alt="..."
                style={{ height: 100, aspectRatio: 1, objectFit: "contain" }}
              />
              <CardBody style={{ fontWeight: "bold", color: "white" }}>
                <hr />
                <CardTitle>{value.title}</CardTitle>
                <hr />
                <CardText className="text-center">
                  <i className="nc-icon nc-calendar-60" />
                  <br />
                  {renderDate(value)}
                </CardText>
                {value.location && (
                  <>
                    <hr />
                    <CardText className="text-center">
                      <i className="nc-icon nc-pin-3" />
                      <br />
                      {value.location}
                    </CardText>
                  </>
                )}
              </CardBody>
            </Card>
          )}
        </Link>
      </Col>
    );
  };

  function GenerateEntries(category) {
    // calendarEntry.sort((a, b) => (a.date_begin > b.date_begin ? 1 : -1));

    // filter acording to categories
    const calendarEntriesCategoryFiltered = calendarEntry.filter(
      (value) =>
        value.entry_type === category ||
        category === "*" ||
        value.entry_type === "all"
    );

    // filter according to current year
    // const calendarEntriesDateYearFiltered =
    //   calendarEntriesCategoryFiltered.filter(
    //     (value) =>
    //       new Date(value.date_begin).getFullYear() === new Date().getFullYear()
    //   );

    let calendarEntries = calendarEntriesCategoryFiltered;

    // if (isSignedIn) {
    //   calendarEntries = calendarEntriesCategoryFiltered;
    // }
    if (!isSummary) {
      return (
        monthYears &&
        calendarEntries &&
        monthYears.map((date) => {
          let options = {
            month: "long",
            year: "numeric",
          };

          const calendarEntriesDateFiltered = calendarEntries.filter(
            (value) => new Date(value.date_begin).setDate(1) === date
          );

          if (
            calendarEntriesDateFiltered &&
            calendarEntriesDateFiltered.length > 0
          ) {
            return (
              <div key={date}>
                <h4>{new Date(date).toLocaleDateString("en-US", options)}</h4>
                <br />
                <Row>
                  {calendarEntriesDateFiltered
                    .sort((a, b) => (a.date_begin > b.date_begin ? 1 : -1))
                    .map((value, index) => itemCards(value, index, date))}
                </Row>
              </div>
            );
          } else {
            return <div key={date} />;
          }
        })
      );
    } else {
      if (calendarEntries && calendarEntries.length > 0) {
        return (
          <Table size="sm">
            <thead>
              <tr>
                <th>Event Date(s)</th>
                <th></th>
                <th>Event Title</th>
                <th>Event Location</th>
              </tr>
            </thead>
            <tbody>
              {calendarEntries
                .sort((a, b) => (a.date_begin > b.date_begin ? 1 : -1))
                .map((value, index) => itemRows(value, index))}
            </tbody>
          </Table>
        );
      }
    }
  }

  return (
    <Container className="tim-container">
      <div id="history">
        <h3 style={{ textTransform: "uppercase" }}>
          {new Date().getFullYear()} Events Calendar
        </h3>{" "}
        <Button color="primary" onClick={() => setIsSummary(!isSummary)}>
          {!isSummary ? "Switch to Summary View" : "Switch to Detailed View"}
        </Button>
        <br />
        <p>
          Please note these dates are subject to change. The calendar will be
          updated from time to time - Please check regularly for updates
        </p>
        <Row>
          <div className="typography-line">
            {isSignedIn && <AddFab path="/club/calendar/form" />}

            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <Nav role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        toggle("1");
                      }}
                      href="#"
                    >
                      All
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        toggle("2");
                      }}
                      href="#"
                    >
                      Club
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        toggle("3");
                      }}
                      href="#"
                    >
                      JKA
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "4" ? "active" : ""}
                      onClick={() => {
                        toggle("4");
                      }}
                      href="#"
                    >
                      KPC
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            {/* Tab panes */}
            {/* <ButtonGroup>
              <Button
                color="primary"
                outline
                onClick={(active) => active}
                // onClick={() => onCheckboxBtnClick(1)}
                // active={cSelected.includes(1)}
              >
                Normal View
              </Button>
            </ButtonGroup> */}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1" id="all">
                {GenerateEntries("*")}
              </TabPane>
              <TabPane tabId="2" id="club">
                {GenerateEntries("club")}
              </TabPane>
              <TabPane tabId="3" id="jka">
                {GenerateEntries("jka")}
              </TabPane>
              <TabPane tabId="4" id="kpc">
                {GenerateEntries("kpc")}
              </TabPane>
            </TabContent>

            <br />
          </div>
        </Row>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.user.isSignedIn,
});

export default connect(mapStateToProps)(SectionCalendar);
