import Header from "@editorjs/header";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import API from "./API";
import { store } from "../redux/store";
import EditorJS from "@editorjs/editorjs";
// import Quote from "@editorjs/quote";
import Quote from "components/EditorJS/Quote/Quote";
import Table from "@editorjs/table";

function EditorJSService(data, readOnly) {
  return new EditorJS({
    /**
     * Id of Element that should contain the Editor
     */
    holder: "editorjs",

    /**
     * Available Tools list.
     * Pass Tool's class or Settings object for each Tool you want to use
     */
    tools: {
      header: {
        class: Header,
        inlineToolbar: ["link"],
        config: {
          levels: [3, 6],
          defaultLevel: 3,
        },
      },
      list: {
        class: List,
        inlineToolbar: true,
      },
      // image: {
      //   class: SimpleImage,
      // },
      image: {
        class: ImageTool,
        config: {
          // endpoints: {
          //   byFile: `${process.env.REACT_APP_API_URL}/Images/`, // Your backend file uploader endpoint
          // },
          additionalRequestHeaders: {
            Authorization: "JWT " + store.getState().user.accessToken,
          },
          field: "file",
          additionalRequestData: {
            title: "Title123",
          },
          uploader: {
            async uploadByFile(file) {
              const formData = new FormData();

              await formData.append("title", "title");
              await formData.append("file", file);
              const url = process.env.REACT_APP_API_URL + "/Images/";
              const response = await API.UploadImage(url, formData);
              if (response.status === 200 || response.status === 201) {
                const value = await response.json();
                return {
                  success: 1,
                  file: {
                    url: value.file,
                  },
                };
              } else {
                return {
                  success: 0,
                  file: {
                    url: "",
                  },
                };
              }
            },
          },
        },
      },
      quote: Quote,
      table: Table,
      // checklist: Checklist,
      // quote: Quote,
      // warning: Warning,
      // marker: Marker,
      // code: CodeTool,
      // delimiter: Delimiter,
      // linkTool: LinkTool,
      // embed: Embed,
      // table: Table,
    },
    placeholder: "Write an awesome article here!",
    data: data,
    readOnly: readOnly,
  });
}

export default EditorJSService;
