// import { createStore } from "redux";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import allReducer from "./allReducer.js";
import userReducer from "./userReducer.js";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["all", "user"],
};

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  all: allReducer,
  user: userReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_REQUEST") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

// export default rootReducer;

export default persistReducer(persistConfig, rootReducer);
