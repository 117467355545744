const INITIAL_STATE = {
  isSignedIn: false,
  accessToken: null,
  refreshToken: null,
};

function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ACCESS": {
      return {
        ...state,
        accessToken: action.value,
      };
    }
    case "REFRESH": {
      return {
        ...state,
        refreshToken: action.value,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isSignedIn: action.value,
      };
    }
    default:
      return state;
  }
}

export default userReducer;
