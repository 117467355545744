import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
} from "reactstrap";
import API from "../../services/API";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import AddFab from "../../components/Functional/AddFab";

// core components

const SectionInstructor = ({ isSignedIn }) => {
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    async function getCalendarEntries() {
      const url = process.env.REACT_APP_API_URL + "/Instructors/";
      const response = await API.APICallUnauthorized(url, "GET", null);
      if (response.status === 200) {
        let data = await response.json();
        setInstructors(data);
      }
    }

    getCalendarEntries().then(() => {});
  }, []);

  const SocialProviders = ({ platform, link }) => {
    switch (platform) {
      case "linkedin":
        return (
          <Button
            className="btn-just-icon btn-linkedin ml-1"
            color="link"
            // href={link}
            onClick={(e) => window.open(link, "_blank")}
          >
            <i className="fa fa-linkedin" />
          </Button>
        );
      case "facebook":
        return (
          <Button
            className="btn-just-icon btn-linkedin ml-1"
            color="link"
            // href={link}
            onClick={(e) => window.open(link, "_blank")}
          >
            <i className="fa fa-facebook" />
          </Button>
        );
      case "instagram":
        return (
          <Button
            className="btn-just-icon btn-google ml-1"
            color="link"
            onClick={(e) => window.open(link, "_blank")}
          >
            <i className="fa fa-instagram" />
          </Button>
        );
      case "twitter":
        return (
          <Button
            className="btn-just-icon btn-twitter"
            color="link"
            onClick={(e) => window.open(link, "_blank")}
          >
            <i className="fa fa-twitter" />
          </Button>
        );
      case "email":
        return (
          <Button
            className="btn-just-icon btn-google ml-1"
            color="link"
            onClick={(e) => window.open(link, "_blank")}
          >
            <i className="fa fa-envelope" />
          </Button>
        );
      default:
        return <div />;
    }
  };

  let itemCards = (value, index) => {
    return (
      <Col md="4" key={index}>
        <Card className="card-profile card-plain">
          <Link to={isSignedIn ? `/club/instructors/form/${value.id}` : "#"}>
            <div className="card-avatar">
              <img alt="..." src={value.image.file} />
            </div>
            <CardBody>
              <div href="#pablo" onClick={(e) => e.preventDefault()}>
                <div className="author">
                  <CardTitle tag="h4">{value.name}</CardTitle>
                  <h6 className="card-category">{value.rank}</h6>
                </div>
              </div>
              <p className="card-description text-center">
                {value.description}
              </p>
            </CardBody>
          </Link>
          <CardFooter className="text-center">
            {value.social.map((item, index) => (
              <SocialProviders
                key={index}
                platform={item.platform}
                link={item.link}
              />
            ))}
          </CardFooter>
        </Card>
      </Col>
    );
  };

  return (
    <Container className="tim-container" style={{ justifyContent: "center" }}>
      <Row>
        <div className="typography-line">
          <h3 style={{ textTransform: "uppercase" }}>Instructors</h3>
          <br />
        </div>
      </Row>
      {isSignedIn && <AddFab path="/club/instructors/form" />}
      <Row>
        <div>{instructors.map((value, index) => itemCards(value, index))}</div>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.user.isSignedIn,
});

export default connect(mapStateToProps)(SectionInstructor);
