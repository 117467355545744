import React from "react";

// reactstrap components
import { Button, Container, Row } from "reactstrap";

// core components

function SectionGrading() {
  const LinkComponent = ({ url, title }) => {
    return (
      <div>
        <Button
          style={{ padding: "0px" }}
          target="_blank"
          href={url}
          className="btn-link mr-1"
          color="info"
        >
          {title}
        </Button>
      </div>
    );
  };

  return (
    <Container className="tim-container" style={{ justifyContent: "center" }}>
      <div id="dojokun">
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="typography-line">
            <h3>GRADING ASSISTANCE</h3>
          </div>
          <div className="typography-line">
            <h6>
              Solis Ortus holds the right to make changes to the policy as it is
              still in draft version.
            </h6>
            <LinkComponent
              title="Grading Curriculum"
              url="http://next.solisortus.co.za/wp-content/uploads/2017/05/Solis-Ortus-Grading-Curriculum.pdf"
            />
            <LinkComponent
              title="Terminology Sheet"
              url="http://next.solisortus.co.za/wp-content/uploads/2018/12/terminology-sheet.pdf"
            />
            <LinkComponent
              title="Junior Kata Requirements"
              url="http://next.solisortus.co.za/wp-content/uploads/2017/05/Kata-Requirements-for-Junior-Gradings.pdf"
            />
            <LinkComponent
              title="Senior Kata Requirements"
              url="http://next.solisortus.co.za/wp-content/uploads/2017/05/Kata-Requirements-for-Senior-Gradings.pdf"
            />
            <LinkComponent
              title="Kata Counts and Kia’s"
              url="http://next.solisortus.co.za/wp-content/uploads/2017/05/Kata-Counts-and-Kias.pdf"
            />
          </div>
        </Row>
      </div>
    </Container>
  );
}

export default SectionGrading;
