import React from "react";

// reactstrap components

// core components
import MainFooter from "components/Footers/MainFooter.js";
import MainNavbar from "components/Navbars/MainNavbar.js";
import OtherPageHeader from "components/Headers/OtherPageHeader";

// index sections
import SectionInstructors from "./SectionInstructors";

function InstructorPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <MainNavbar isBlackBg={true} />
      <OtherPageHeader />
      <SectionInstructors />
      <MainFooter />
    </>
  );
}

export default InstructorPage;
