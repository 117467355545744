import React, { lazy, Suspense } from "react";
// import ReactDOM from "react-dom";
import { Route, Redirect, Switch } from "react-router-dom";
// import PropTypes from "prop-types";
import { connect } from "react-redux";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
import { Spinner } from "reactstrap";
import ArticleEditor from "./articles/ArticleEditor";
import ArticleDisplay from "./articles/ArticleDisplay";
import CalendarPage from "./calendar/CalendarPage";
import CalendarForm from "./calendar/CalendarForm";
import InstructorPage from "./instructors/InstructorPage";
import InstructorForm from "./instructors/InstructorForm";
import Notification from "../components/Functional/Notification";
import PoliciesPage from "./PoliciesPage";
import DojoEtiquette from "./DojoEtiquette";
import GradingPage from "./GradingPage";
import ResetPasswordEmailPage from "./ResetPasswordEmailPage";
import NewPasswordPage from "./NewPasswordPage";
// pages
const Welcome = lazy(() => import("views/Welcome.js"));
const Index = lazy(() => import("views/Index.js"));
// const NucleoIcons = lazy(() => import("views/NucleoIcons.js"));
const LandingPage = lazy(() => import("views/examples/LandingPage.js"));
const ProfilePage = lazy(() => import("views/admin/ProfilePage.js"));
const RegisterPage = lazy(() => import("views/RegisterPage.js"));
const ErrorPage = lazy(() => import("views/ErrorPage.js"));
const HistoryPage = lazy(() => import("views/HistoryPage.js"));
// const BriefHistory = lazy(() => import("./BriefHistory"));
const DojoKun = lazy(() => import("./DojoKunPage"));
const Kihon = lazy(() => import("./KihonPage"));
const Kata = lazy(() => import("./KataPage"));
const Kumite = lazy(() => import("./KumitePage"));
const ArticlesPage = lazy(() => import("./ArticlesPage"));
const TerminologyPage = lazy(() => import("./TerminologyPage"));
const ClassTimesPage = lazy(() => import("./ClassTimesPage"));

// others

function App({ isSignedIn }) {
  let loader = (
    <div
      style={{
        display: "flex",
        marginTop: "50%",
        marginLeft: "50%",
      }}
    >
      <Spinner color="dark" style={{ position: "fixed" }} />
    </div>
  );

  const AuthenticatedPages = () => {
    return (
      <>
        <Route path="/admin" render={(props) => <ProfilePage {...props} />} />
        <Route
          exact
          path="/articles/form"
          render={(props) => <ArticleEditor {...props} />}
          // render={(props) => <ArticlesForm {...props} />}
        />
        <Route
          exact
          path="/articles/form/:id"
          render={(props) => <ArticleEditor {...props} />}
        />
        <Route
          exact
          path="/club/calendar/form"
          render={(props) => <CalendarForm {...props} />}
        />
        <Route
          exact
          path="/club/calendar/form/:id"
          render={(props) => <CalendarForm {...props} />}
        />
        <Route
          exact
          path="/club/instructors/form"
          render={(props) => <InstructorForm {...props} />}
        />
        <Route
          exact
          path="/club/instructors/form/:id"
          render={(props) => <InstructorForm {...props} />}
        />
      </>
    );
  };

  return (
    <Suspense fallback={loader}>
      <Notification />
      <Switch>
        <Route exact path="/" render={(props) => <Welcome {...props} />} />
        <Route
          path="/login"
          render={() =>
            isSignedIn ? <Redirect to="/admin" /> : <RegisterPage />
          }
        />
        <Route
          path="/reset-password-email"
          render={(props) => <ResetPasswordEmailPage {...props} />}
        />
        <Route
          path="/password/reset/confirm/:id/:token"
          render={(props) => <NewPasswordPage {...props} />}
        />
        <Route
          path="/jka/history"
          render={(props) => <HistoryPage {...props} />}
        />
        {/* <Route path="/articles" render={(props) => <Index {...props} />} /> */}
        <Route path="/examples" render={(props) => <Index {...props} />} />
        <Route
          path="/examples-landing"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/jka/dojo-kun"
          render={(props) => <DojoKun {...props} />}
        />
        <Route
          path="/jka/karate/kihon"
          render={(props) => <Kihon {...props} />}
        />
        <Route
          path="/jka/karate/kata"
          render={(props) => <Kata {...props} />}
        />
        <Route
          path="/jka/karate/kumite"
          render={(props) => <Kumite {...props} />}
        />
        <Route
          exact
          path="/articles"
          render={(props) => <ArticlesPage {...props} />}
        />
        <Route
          exact
          path="/club/instructors"
          render={(props) => <InstructorPage {...props} />}
        />
        <Route
          exact
          path="/club/calendar"
          render={(props) => <CalendarPage {...props} />}
        />
        {!isSignedIn && (
          <Route
            exact
            path="/articles/:id"
            render={(props) => <ArticleDisplay {...props} />}
          />
        )}
        <Route
          exact
          path="/jka/terminology"
          render={(props) => <TerminologyPage {...props} />}
        />
        <Route
          path="/club/class-times"
          render={(props) => <ClassTimesPage {...props} />}
        />

        <Route
          exact
          path="/club/policies"
          render={(props) => <PoliciesPage {...props} />}
        />
        <Route
          exact
          path="/club/dojo/etiquette"
          render={(props) => <DojoEtiquette {...props} />}
        />
        <Route
          exact
          path="/club/dojo/grading"
          render={(props) => <GradingPage {...props} />}
        />
        {isSignedIn && <AuthenticatedPages />}
        <Route path="/not-found" render={(props) => <ErrorPage {...props} />} />
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  );
}

// App.propTypes = {
//   children: PropTypes.element,
//   classes: PropTypes.object,
// };

const mapStateToProps = (state) => ({
  isSignedIn: state.user.isSignedIn,
});

export default connect(mapStateToProps)(App);
