import React from "react";
import { Button } from "reactstrap";

const AddFab = ({ path }) => {
  return (
    <Button
      className="btn-just-icon ml-1 btn-fab"
      color="danger"
      type="button"
      size="lg"
      href={path}
    >
      <i className="fa fa-plus" />
    </Button>
  );
};

export default AddFab;
