import React, { useState } from "react";
import API from "../services/API";

// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormFeedback,
  FormGroup,
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import {connect} from "react-redux";

const ResetPasswordEmailPage = ({ dispatch }) => {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const [username, setUsername] = useState("");
  const [usernameMessage, setUsernameMessage] = useState("");
  const [checkValid, setCheckValid] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);

  function validateEmail(userName) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(userName).toLowerCase());
  }

  async function checkEnterKey(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      await submitPasswordResetRequest();
    }
  }

  async function submitPasswordResetRequest() {
    try {
      const body = {
        email: username,
      };
      const url = process.env.REACT_APP_API_URL + "/auth/users/reset_password/";
      let response = await API.APICallUnauthorized(url, "POST", body);
      if (response.status === 401) {
        setEmailError(true);
        setCheckValid(false);
      } else if (response.status === 400) {
        const errorMessage = (await response.json())?.email[0];
        setEmailError(true);
        setCheckValid(false);
        setUsernameMessage(errorMessage);
      } else if (response.status === 204) {
        dispatch({ type: "EMAILSENT", value: true });
        setEmailSent(true);
        setEmailError(false);
      }
    } catch (e) {
      console.log("An error occurred", e);
    }
  }

  async function handleTryAgain() {
    setEmailSent(false);
  }

  return (
    <React.Fragment>
      <MainNavbar isBlackBg={true} />
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + require("assets/img/HomePageImage.jpg").default + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card-register ml-auto mr-auto">
                <h3 className="title mx-auto">Reset Password</h3>
                <Form className="register-form">
                  {emailSent ? (
                    <Form noValidate>
                      <p>
                        An email with a password reset link was sent to you.
                        Please open the link in the email to reset your
                        password.
                      </p>
                      <p>Email not received?</p>
                      <Button
                        fullWidth
                        className="btn-round"
                        color="default"
                        onClick={handleTryAgain}
                        block
                      >
                        Try again
                      </Button>
                    </Form>
                  ) : (
                    <FormGroup>
                      <label>Email</label>
                      <Input
                        invalid={emailError}
                        valid={checkValid}
                        type="text"
                        error={"usernameError"}
                        // helperText={usernameMessage}
                        // required
                        onChange={(event) => {
                          setUsernameMessage("");
                          setEmailError(false);
                          // setCheckValid(true);
                          // setCheckValid(false);
                          setUsername(event.target.value);
                          setCheckValid(validateEmail(event.target.value));
                        }}
                        value={username}
                        onKeyPress={(event) => checkEnterKey(event)}
                        placeholder="Email"
                      />
                      <Button
                        block
                        className="btn-round"
                        color="default"
                        onClick={submitPasswordResetRequest}
                        // target="_blank"
                      >
                        SUBMIT
                      </Button>
                      {/*<FormFeedback valid tooltip>*/}
                      {/*  Sweet! that name is available*/}
                      {/*</FormFeedback>*/}
                      <FormFeedback tooltip>{usernameMessage}</FormFeedback>
                    </FormGroup>
                  )}
                </Form>
                {/*{emailSent ? <NewPasswordForm /> : <UserNameForm />}*/}
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="footer register-footer text-center">
          <h6>
            © {new Date().getFullYear()}, made by
            <Button
              style={{ padding: "5px", marginBottom: "3px" }}
              className="btn-link"
              color="danger"
              target="_blank"
              href="https://intentio.co.za"
            >
              Intentio
            </Button>
          </h6>
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect()(ResetPasswordEmailPage);
