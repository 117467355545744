import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// nodejs library that concatenates strings
import classnames from "classnames";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function MainNavbar({ isSignedIn, dispatch, isBlackBg }) {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  const history = useHistory();

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299 ||
        !isBlackBg
      ) {
        setNavbarColor("white");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    window.addEventListener("load", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
      window.removeEventListener("load", updateNavbarColor);
    };
  });

  function handleSignOut(e) {
    e.preventDefault();
    dispatch({ type: "SIGNOUT_REQUEST", value: false });
    window.location.href = "/";
    // history.push("/");
  }

  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/"
            // target="_blank"
            title="Solis Ortus JKA Karate"
          >
            Solis Ortus
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink
                href="https://www.instagram.com/solis_ortus_karate_club/"
                target="_blank"
                title="Follow us on Instagram"
                // onClick={(e) => e.preventDefault()}
              >
                <i aria-hidden={true} className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://www.facebook.com/solis.ortus.9"
                target="_blank"
                title="Follow us on Facebook"
                // onClick={(e) => e.preventDefault()}
              >
                <i aria-hidden={true} className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                JKA Karate
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="/jka/history">A Brief History</DropdownItem>
                <DropdownItem href="/jka/dojo-kun">Dojo Kun</DropdownItem>
                <DropdownItem href="/jka/terminology">Terminology</DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>3 K's of Karate</DropdownItem>
                <DropdownItem href="/jka/karate/kihon">Kihon</DropdownItem>
                <DropdownItem href="/jka/karate/kata">Kata</DropdownItem>
                <DropdownItem href="/jka/karate/kumite">Kumite</DropdownItem>
                {/*                 // <DropdownItem href="/jka/terminology/basic">Basic</DropdownItem>
                // <DropdownItem href="/jka/terminology/grading">
                //   Grading
                // </DropdownItem>
                // <DropdownItem href="/jka/terminology/kata">Kata</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Club
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="/club/calendar">Calendar</DropdownItem>
                <DropdownItem href="/club/class-times">
                  Class Times
                </DropdownItem>
                <DropdownItem href="/club/policies">Policies</DropdownItem>
                <DropdownItem href="/club/instructors">
                  Instructors
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem header>In the Dojo</DropdownItem>
                <DropdownItem href="/club/dojo/etiquette">
                  Dojo etiquette
                </DropdownItem>
                <DropdownItem href="/club/dojo/grading">
                  Grading pack
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*<UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Articles
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem href="/articles">View Articles</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>*/}
            <NavItem>
              <NavLink href="/articles">Articles</NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  navbarCollapse ? toggleNavbarCollapse() : setTimeout(0);
                  setTimeout(() => {
                    history.push("/");
                  }, 200);
                  dispatch({ type: "SCROLL", value: true });
                }}
              >
                Contact Us
              </NavLink>
            </NavItem>
            <NavItem>
              {isSignedIn ? <NavLink href="/admin">Admin</NavLink> : null}
            </NavItem>
            <NavItem>
              {isSignedIn ? (
                <NavLink href="#" onClick={handleSignOut}>
                  <i className="nc-icon nc-single-02" />
                  <p> Sign Out</p>
                </NavLink>
              ) : (
                <NavLink href="/login">
                  <i className="nc-icon nc-single-02" />
                  <p> Sign in</p>
                </NavLink>
              )}
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

const mapStateToProps = (state) => ({
  isSignedIn: state.user.isSignedIn,
});

export default connect(mapStateToProps)(MainNavbar);
