import React, { useEffect } from "react";

import MainNavbar from "../../components/Navbars/MainNavbar";
import OtherPageHeader from "../../components/Headers/OtherPageHeader";
import { Container } from "reactstrap";
import { useParams } from "react-router";
import "./ArticleEditor.scss";
import MainFooter from "../../components/Footers/MainFooter";
import API from "../../services/API";
import { connect } from "react-redux";
import EditorJSService from "services/EditorJSService";

const ArticleDisplay = () => {
  const { id } = useParams();

  useEffect(() => {
    async function getArticle(id) {
      if (id === undefined) return undefined;

      const url = process.env.REACT_APP_API_URL + "/Pages/" + id;
      const response = await API.APICallUnauthorized(url, "GET", null);
      if (response.status === 200) {
        let data = await response.json();
        if (data.sections.length > 0) return data.sections[0];
        else return undefined;
      } else {
        return undefined;
      }
    }

    getArticle(id).then((data) => {
      EditorJSService(data, true);
    });
  }, [id]);

  return (
    <>
      <MainNavbar isBlackBg={true} />
      <OtherPageHeader />
      <div className="main">
        <Container className="tim-container">
          <div id="editorjs" />
        </Container>
      </div>
      <MainFooter />
    </>
  );
};

export default connect()(ArticleDisplay);
