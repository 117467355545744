import React, { useEffect, useState } from "react";

import MainNavbar from "../../components/Navbars/MainNavbar";
import OtherPageHeader from "../../components/Headers/OtherPageHeader";
import {
  Button,
  Card,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { useParams } from "react-router";
import "./ArticleEditor.scss";
import styles from "../../assets/scss/styles.module.scss";
import MainFooter from "../../components/Footers/MainFooter";
import API from "../../services/API";
import { useHistory } from "react-router-dom";

import EditorJSService from "services/EditorJSService";
import {connect} from "react-redux";

const ArticleEditor = ({ dispatch }) => {
  const { id } = useParams();
  const history = useHistory();
  const [editor, setEditor] = useState(null);

  const [article, setArticle] = useState({
    author: "",
    date: new Date().toISOString().substring(0, 10),
    category: [],
    main_heading: "",
    feature_image: "",
    sections: [],
  });

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };
  const hasImage = (section) => section.type === "image";

  const [invalidMessage, setInvalidMessage] = useState("");

  useEffect(() => {
    async function getArticle(id) {
      // if (id === undefined) return undefined;
      const url = process.env.REACT_APP_API_URL + "/Pages/" + id;
      const response = await API.getAPIData(url, "GET", null);
      if (response.status === 200) {
        let data = await response.json();
        setArticle(data);
        if (data.sections.length > 0) return data.sections[0];
        else return undefined;
      } else {
        return undefined;
      }
    }

    getArticle(id).then((data) => {
      setEditor(EditorJSService(data, false));
    });
  }, [id]);

  // ons kan die ook 'n api call maak waar jy categories kan add of delete.
  // net maar n lys vir nou om funktionaliteit te toets
  const categoryOptions = [
    "Club",
    "Grading",
    "Seniors",
    "Juniors",
    "Mini's",
    "KPC",
    "General",
  ];

  function categoryCheckBox(valueAll, indexAll) {
    return (
      <CustomInput
        key={indexAll}
        type="checkbox"
        id={indexAll}
        label={valueAll}
        inline
        checked={article.category.includes(valueAll)}
        onChange={(event) => {
          if (article.category.indexOf(valueAll) < 0) {
            article.category.push(valueAll);
            setArticle({ ...article });
          } else {
            article.category.splice(article.category.indexOf(valueAll), 1);
            setArticle({ ...article });
          }
        }}
      />
    );
  }

  async function deleteArticle() {
    const url = process.env.REACT_APP_API_URL + "/Pages/" + id;
    const response = await API.getAPIData(url, "DELETE", article);
    // // Delete was successful
    if (response.status === 204) {
      dispatch({
        type: "CONFIRMATION",
        value: { color: "success", title: "Successfully delete", open: true },
      });
      history.goBack();
    }
  }

  async function submitAPI(tempArticle) {
    const url =
      process.env.REACT_APP_API_URL + "/Pages/" + (id ? id + "/" : "");
    const method = id ? "PUT" : "POST";

    const response = await API.getAPIData(url, method, tempArticle);

    if (response.status === 200 || response.status === 201) {
      dispatch({
        type: "CONFIRMATION",
        value: { color: "success", title: "Submission successful", open: true },
      });
      history.goBack();
    }
  }

  async function submitArticle() {
    if (editor) {
      const articleContent = await editor.save();
      if (articleContent.blocks.length === 0) {
        setInvalidMessage("No content was added to the article.");
        toggleModal();
      } else if (articleContent.blocks[0].type !== "header") {
        setInvalidMessage(
          "Please ensure that the first item in the article is a Heading."
        );
        toggleModal();
      } else if (!articleContent.blocks.some(hasImage)) {
        setInvalidMessage(
          "Please ensure that at least one image is specified in the article. " +
            "This image will be used as the featured image."
        );
        toggleModal();
      } else if (!article.author) {
        setInvalidMessage("Please provide a value for the author field.");
        toggleModal();
      } else if (!article.category.length) {
        setInvalidMessage("Please select at least one category.");
        toggleModal();
      } else {
        try {
          let tempArticle = article;
          tempArticle.main_heading = articleContent.blocks[0].data.text;
          tempArticle.feature_image = articleContent.blocks
            .findIndex(hasImage)
            .toString(); // Store the index of the feature image
          tempArticle.sections = [articleContent];
          await submitAPI(tempArticle);
          setArticle(tempArticle);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  return (
    <>
      <MainNavbar isBlackBg={true} />
      <OtherPageHeader />
      <Modal isOpen={modal} toggle={toggleModal}>
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="modal-title text-center" id="exampleModalLabel">
            Invalid input
          </h5>
        </div>
        <div className="modal-body">{invalidMessage}</div>
        <div className="modal-footer">
          {/*<div className="left-side">*/}
          {/*  <Button*/}
          {/*    className="btn-link"*/}
          {/*    color="default"*/}
          {/*    type="button"*/}
          {/*    onClick={toggleModal}*/}
          {/*  >*/}
          {/*    Never mind*/}
          {/*  </Button>*/}
          {/*</div>*/}
          {/*<div className="divider" />*/}
          <div className="right-side">
            <Button
              className="btn-link"
              color="danger"
              type="button"
              onClick={toggleModal}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <div className="main">
        <Container className="tim-container">
          <Row>
            <Col className="ml-auto mr-auto" lg="12">
              <Card className={styles.cardForm}>
                <Form>
                  <FormGroup>
                    <h3>{id ? "EDIT ARTICLE" : "ADD ARTICLE"}</h3>
                  </FormGroup>
                  <FormGroup>
                    <Label>Author</Label>
                    <Input
                      id="author"
                      placeholder="Author"
                      type="text"
                      onChange={(event) =>
                        setArticle({ ...article, author: event.target.value })
                      }
                      value={article.author}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Date</Label>
                    <Input
                      id="date"
                      placeholder="Date"
                      type="date"
                      onChange={(event) =>
                        setArticle({ ...article, date: event.target.value })
                      }
                      value={article.date}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Category</Label>
                    <div>
                      {categoryOptions.map((value, index) => {
                        return categoryCheckBox(value, index);
                      })}
                    </div>
                  </FormGroup>

                  <div id="editorjs" />

                  {/* <Button
                    color="success"
                    outline
                    size="sm"
                    type="button"
                    className="mr-1"
                    onClick={submitArticle}
                  >
                    Submit
                  </Button>
                  {id && (
                    <Button
                      color="danger"
                      outline
                      size="sm"
                      type="button"
                      className="mr-1"
                      onClick={deleteArticle}
                    >
                      Delete
                    </Button>
                  )} */}
                  <Row>
                    <Col>
                      <Button
                        color="success"
                        outline
                        size="sm"
                        type="button"
                        className="mr-1"
                        onClick={submitArticle}
                      >
                        Submit
                      </Button>

                      {id && (
                        <Button
                          color="danger"
                          outline
                          size="sm"
                          type="button"
                          className="mr-1"
                          onClick={deleteArticle}
                        >
                          Delete
                        </Button>
                      )}
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <Button
                        color="danger"
                        outline
                        size="sm"
                        type="button"
                        className="mr-1"
                        onClick={(e) => {
                          history.goBack();
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <MainFooter />
    </>
  );
};

export default connect()(ArticleEditor);
